// Inspired by https://github.com/Uniswap/interface/blob/main/src/utils/index.ts

import { isAddress } from "@ethersproject/address";
import { Contract } from "@ethersproject/contracts";
import { JsonRpcProvider, JsonRpcSigner } from "@ethersproject/providers";
import { MFER_ABI, MFER_ADDRESS, MNS_ABI, MNS_ADDRESS, MNS_INIT_DROP_QTY, MNS_MINT_START } from "../constants";

// Contract Utils
export function getSigner(library: JsonRpcProvider, account: string): JsonRpcSigner {
    return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library: JsonRpcProvider, account?: string): JsonRpcProvider | JsonRpcSigner {
    return account ? getSigner(library, account) : library;
}

export function getContract(address: string, ABI: any, library: JsonRpcProvider, account?: string): Contract {
    return new Contract(address, ABI, getProviderOrSigner(library, account));
}

export function getMnsContract(library: JsonRpcProvider, account: string): Contract {
    return getContract(MNS_ADDRESS, MNS_ABI, library, account)
}

export function getMferContract(library: JsonRpcProvider, account: string): Contract {
    return getContract(MFER_ADDRESS, MFER_ABI, library, account)
}

export function formatAddress(address: string | null | undefined): string {
    return (address === undefined || address === null)
        ? ""
        : truncateAddress(address)
}

export function truncateAddress(address: string): string {
    return `${address.substring(0,6)}...${address.substring(42-4, 42)}`;
}

export function isValidAddress(address: string): boolean {
    return isAddress(address)
}

export function checkTotalMintable(): number {
    const currentTs = Math.floor((new Date()).getTime() / 1000)
    const timeSince = currentTs - MNS_MINT_START
    if (timeSince > 0) {
        const subtotal = MNS_INIT_DROP_QTY + Math.floor(timeSince / 7200)
        return (subtotal > 2222) ? 2222 : subtotal
    } else {
        return 0
    }
}

export function getNetworkName(chainId: number | undefined): string {
    if (chainId === undefined) {
        return "Unknown"
    }

    if (chainId === 1) {
        return "Mainnet"
    } else if (chainId === 3) {
        return "Ropsten"
    } else if (chainId === 4) {
        return "Rinkeby"
    } else if (chainId === 42) {
        return "Hardhat-42"
    } else if (chainId === 1337) {
        return "Hardhat-1337"
    } else {
        return "Unknown"
    }
}
