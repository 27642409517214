import { mferAbi } from "../abi/mfer"
import { mnsAbi } from "../abi/mns"

export const MNS_ADDRESS = "0x54b003d84ce7829b5715efdb52a1bc113c2155f4"
export const MNS_ABI = mnsAbi
export const MNS_NAME = "NoSimpMfers"
export const MNS_DROP_DATE = "April 30th"
export const MNS_INIT_DROP_QTY: number = 100
export const MNS_MINT_PRICE: number = 0.03
export const MNS_OG_MINT_PRICE: number = 0.01
// export const MNS_MINT_START: number = 1650153600 // 2022-04-17
export const MNS_MINT_START: number = 1651276800 // 2022-04-30
// export const MNS_MINT_START: number = 1651363200 // 2022-05-01 00:00:00

export const MFER_ADDRESS = "0x79FCDEF22feeD20eDDacbB2587640e45491b757f"
export const MFER_ABI = mferAbi
export const MFER_USE_MOCK_BALANCE: boolean = true
export const MFER_MOCK_BALANCE: number = 0
export const MFER_OS_URL = "https://opensea.io/collection/mfers"
