import { Box, Center, Flex, FormControl, Heading, Image, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from "@chakra-ui/react"
import { BigNumber } from "@ethersproject/bignumber"
import { parseEther } from "@ethersproject/units"
import { useEffect, useState } from "react"
import { MFER_MOCK_BALANCE, MFER_USE_MOCK_BALANCE, MNS_MINT_PRICE, MNS_NAME, MNS_OG_MINT_PRICE } from "../constants"
import { checkTotalMintable, getMferContract, getMnsContract } from "../utils"
import { MintButton } from "./MintButton"

interface MintBlockProp {
    account: string | null | undefined,
    active: boolean,
    activate: any,
    library: any,
}

export const MintBlock = ({
    account, 
    active, 
    activate, 
    library,
} : MintBlockProp) => {

    const [ mintQuantity, setMintQuantity ] = useState(0)
    const [ available, setAvailable ] = useState(0)
    const [ totalSupply, setTotalSupply ] = useState(0)
    const [ mferBalance, setMferBalance ] = useState(0)
    const [ mintMsg, setMintMsg ] = useState("")
    
    const handleInputChange = (value: string) => setMintQuantity(parseInt(value))
    // console.log(`${mintQuantity} selected`)
    // console.log(`MferBalance: ${mferBalance}`)

    const mintXSM = (quantity: number, mintPrice: number) => {
        console.log(`Attempted mint - [Qty: ${quantity}, MintPrice: ${mintPrice}, MferBal: ${mferBalance}]`)
        setMintMsg("")

        // Validate
        if (quantity === 0 || mintPrice === 0) {
            console.error(`Unexpected mint parameters - [Qty: ${quantity}, MintPrice: ${mintPrice}]`)
            return
        }

        const mnsContract = getMnsContract(library, account ? account : "")
        if (mnsContract === null || mnsContract === undefined) {
            return
        }

        if (mferBalance > 0) {
            console.log(`Executing ogMint()`)
            return mnsContract.ogMint(quantity, {
                value: parseEther(mintPrice.toString()),
            })
            .then((x: any) => {
                console.log(x)
                setMintMsg(x?.hash.toString())
            })
            .catch((error: any) => {
                // console.error(error)
                // const errMsg = error?.data?.message
                const errMsg = error?.message
                console.error(errMsg)
                setMintMsg(errMsg)
            })
        } else {
            console.log(`Executing mint()`)
            return mnsContract.mint(quantity, {
                value: parseEther(mintPrice.toString()),
            })
            .then((x: any) => {
                console.log(x)
                setMintMsg(x?.hash.toString())
            })
            .catch((error: any) => {
                // console.error(error)
                // const errMsg = error?.data?.message
                const errMsg = error?.message
                console.error(errMsg)
                setMintMsg(errMsg)
            })
        }
    }

    useEffect(() => {
        if (!account) {
            console.warn(`No account detected - ${account}`)
            return
        }

        const mferContract = getMferContract(library, account ? account : "")
        mferContract.balanceOf(account)
            .then((amount: any) => {
                const _mferBalance = amount ? BigNumber.from(amount).toNumber() : 0
                setMferBalance(_mferBalance)
            })
            .catch((error: Error) => {
                console.error(error)
            })


        if (MFER_USE_MOCK_BALANCE) {
            setMferBalance(MFER_MOCK_BALANCE)
        }

    }, [account, library])

    useEffect(() => {
        if (!account) {
            console.warn(`No account detected - ${account}`)
            return
        }

        const contract = getMnsContract(library, account ? account : "")
        contract.totalMintable()
            .then((amount: any) => {
                const totalMintable = BigNumber.from(amount).toNumber()
                console.log(`Validate - [totalMintable(): ${totalMintable}, check: ${checkTotalMintable()}]`)
                setAvailable(totalMintable)
            })
            .catch((error: Error) => {
                console.error(error)
            })

        contract.totalSupply()
            .then((amount: any) => {
                const _totalSupply = BigNumber.from(amount).toNumber()
                // console.log(`Validate - [totalMintable(): ${totalMintable}, check: ${checkTotalMintable()}]`)
                setTotalSupply(_totalSupply)
            })
            .catch((error: Error) => {
                console.error(error)
            })

    }, [account, library])

    return (
        <Box textAlign="center">

            <Box bg='yellow' textAlign="center" fontSize="xl" color='black'>

                <Heading px={10} py={10} fontSize='3xl'>
                    {MNS_NAME}
                </Heading>

                <Box mx='auto' px={10} py={5}>
                    <Center>
                        <Image 
                            bg='white'
                            boxSize='sm'
                            boxShadow='2xl'
                            rounded='lg'
                            src='images/mns_animated.gif'
                            alt='xsmfers animation' 
                        />
                    </Center>
                </Box>

                
                <Flex py={5} align='center' w={300} mx="auto">
                    <FormControl>
                        <NumberInput 
                            max={20} min={1} 
                            bg="white" w={100} mx="auto"
                            onChange={handleInputChange}
                        >
                            <NumberInputField id='amount' />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </FormControl>

                    <MintButton 
                        account={account}
                        active={active}
                        library={library}
                        activate={activate}
                        quantity={mintQuantity}
                        mferBalance={mferBalance}
                        mintFunc={mintXSM}
                        mintMsg={mintMsg}
                    />
                </Flex>

            </Box>

            <Box bg='black'>
                <Box px={10} py={5} color='white'>
                    Minted / Dropped: {active ? totalSupply : '??'} / {active ? available : '??'}
                </Box>

                {/* <Box px={10} py={5} color='white'>
                    Total Dropped: {available}
                </Box> */}

                <Box px={10} py={5} color='white'>
                    Mint Price: {MNS_MINT_PRICE}Ξ (with MFER: {MNS_OG_MINT_PRICE}Ξ)
                </Box>

                {/* <Box px={10} py={5} color='white'>
                    2222 {MNS_NAME}
                </Box> */}

                {/* <Box px={10} py={5} color='white'>
                    {MNS_INIT_DROP_QTY} dropped on {MNS_DROP_DATE}
                </Box> */}

                <Box px={10} py={5} color='white'>
                    New drop every 2 hours, until 2222 are minted
                </Box>
            </Box>
            
        </Box>
    )
}
