import { Box } from "@chakra-ui/react"
import { useWeb3React } from "@web3-react/core"
import { MintBlock } from "./MintBlock"
import { WalletHeader } from "./WalletHeader"

export const SiteBlock = () => {

    const { account, active, activate, library, chainId } = useWeb3React()

    return (
        <Box textAlign="center" fontSize="xl">
            <WalletHeader 
                account={account}
                active={active}
                chainId={chainId}
            />
            <MintBlock 
                account={account}
                active={active}
                activate={activate}
                library={library}
            />
        </Box>
    )
}
