import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Link, useDisclosure } from "@chakra-ui/react"
import { InjectedConnector, NoEthereumProviderError } from "@web3-react/injected-connector"

import { MNS_MINT_PRICE, MNS_OG_MINT_PRICE } from "../constants"

const injectedConnector = new InjectedConnector({
    supportedChainIds: [
        1, // Mainnet
        3, // Ropsten
        4, // Rinkeby
        5, // Goerli
        42, // Hardhat
        1337, // Hardhat
    ]
})

interface MintButtonProp {
    account: string | null | undefined,
    active: boolean,
    library: any,
    activate: any,
    quantity: number,
    mferBalance: number,
    mintFunc: CallableFunction,
    mintMsg: string,
}

export const MintButton = (props : MintButtonProp) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    // const cancelRef = useRef()

    const totalMintPrice = () => {
        const mintPrice: number = (props.mferBalance > 0) ? MNS_OG_MINT_PRICE : MNS_MINT_PRICE
        return (props.quantity * mintPrice).toFixed(2)
        // return mintPrice
    }

    const buttonText = () => {
        if (!props.active) {
            return 'Connect'
        }

        return (props.mferBalance > 0) 
            ? `OG Mint! (${totalMintPrice()}Ξ)` 
            : `Mint! (${totalMintPrice()}Ξ)`
    }

    // TODO: limit mint quantity?
    const onClick = () => {
        if (!props.active) {
            props.activate(injectedConnector, undefined, true).catch((e: any) => {
                if (e instanceof NoEthereumProviderError) {
                    const requirement = "MetaMask"
                    alert(`Please install ${requirement} to connect!`)
                } else {
                    console.error(e)
                }
            })
        } else {
            props.mintFunc(props.quantity, totalMintPrice())
                .then(onOpen)
        }
    }

    const buttonIsActive = () => {
        return (!props.active)
            ? true
            : (props.quantity > 0)
    }

    const formatMintMsg = (msg: string) => {
        if (msg.startsWith("0x")) {
            const url = `https://etherscan.io/tx/${msg}`
            return (
                <Link href={url} isExternal>
                    View Transaction
                </Link>
            )
        } else if (msg.includes('denied transaction')) {
            return `User rejected signature`
        } else {
            return `Unknown error`
        }
    }

    return (
        <>
        <Button 
            px={20}
            colorScheme="blue" ml="auto" mr="auto" textAlign="center"
            onClick={onClick}
            isDisabled={!buttonIsActive()}
        >
            {buttonText()}
        </Button>

        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={undefined}
            onClose={onClose}
        >

            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        Mint Status
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {formatMintMsg(props.mintMsg)}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button onClick={onClose}>
                            OK
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
            
        </AlertDialog>

        </>
    )
}
