import { Box } from "@chakra-ui/react"
import { formatAddress, getNetworkName } from "../utils"

interface WalletHeaderProp {
    account: string | null | undefined,
    active: boolean,
    chainId: number | undefined,
}

export const WalletHeader = ({account, active, chainId}: WalletHeaderProp) => {

    console.log(`Chain: ${getNetworkName(chainId)}, Address: ${formatAddress(account)}, Active: ${active}`)

    const getMessage = () => {
        return active ? `Connected: ${formatAddress(account)} (${getNetworkName(chainId)})` : `Disconnected`
    }

    return (
        <Box bg='black' color='white' py={1}>
            {getMessage()}
        </Box>
    )
}
